<!-- Start Banner Area -->
<section class="banner-area jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-md-12">
                        <div class="banner-content">
                            <span class="wow fadeInDown" data-wow-delay="1s">Our Main Purpose</span>
                            <h1 class="wow fadeInDown" data-wow-delay="1s">Living Benefits Team</h1>
                            <p style="color:rgb(11, 8, 141)" >We understand the importance of providing coverage that offers our clients and their loved ones peace of mind. That's why we offer Life Insurance Policies with Living Benefits.</p>
                            <p style="color:rgb(11, 8, 141)" class="wow fadeInLeft" data-wow-delay="1s">Our most cost-effective design is a Term Policy with Living Benefits at no additional cost.</p>
                            <p style="color:rgb(11, 8, 141)" class="wow fadeInLeft" data-wow-delay="1s">The Policy allows you to receive up to a $1,000,000 Tax-Free lump sum payment while living if the insured experiences a Chronic illness, Critical illness, or Critical Injury.</p>

                            <!-- <div class="banner-btn wow fadeInUp" data-wow-delay="1s">
                                <a routerLink="/contact-us" class="default-btn">Contact Us</a>
                            </div> -->
                        </div>
                    </div>

                    <div class="col-lg-5 col-md-12">
                        <div class="banner-img">
                            <!-- <img src="assets/img/banner/test.jpg" alt="Image"> -->
                            <div class="banner-shape-1">
                                <img src="assets/img/banner/banner-shape-1.png" alt="Image">
                            </div>
                            <!-- <ul>
                                <li><a href="#" target="_blank">Facebook</a></li>
                                <li><a href="#" target="_blank">Instagram</a></li>
                                <li><a href="#" target="_blank">Twitter</a></li>
                                <li><a href="#" target="_blank">Linkedin</a></li>
                            </ul> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Banner Area -->
