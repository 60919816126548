<!-- Start Team Area -->
<section class="team-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="top-title">Our Agent</span>
            <h2>Meet Our Expert Team</h2>
        </div>

        <div class="team-slider">
            <owl-carousel-o [options]="teamSlidesOptions">
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/img/team/CM.jpeg" alt="Image">
                            <ul class="team-link">
                                <!-- <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li> -->
                            </ul>
                        </div>
                        <div class="team-content">
                            <h3>Christopher Muro</h3>
                            <span>Managing Partner</span>
                            <p>Chris has significant experience advising clients from all walks of life on the appropriate insurance designs to meet their needs. While founding the Living Benefits Team, Chris advises on business development at one of New York City's most prominent proprietary trading firms. In addition, Chris has spent the past 23 years as a pioneer in the Insurance and Electronic Trading Markets.



                                Chris holds the Series 7, 24, 63 securities and Insurance license 215 Certifications</p>
                        </div>
                    </div>
                </ng-template>

                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/img/team/Rich.jpeg" alt="Image">
                            <ul class="team-link">
                                <!-- <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li> -->
                            </ul>
                        </div>
                        <div class="team-content">
                            <h3>Richard “Big Daddy” Salgado</h3>
                            <span>President of Coastal Advisors LLC Insurance Consultants</span>
                            <p>President of Coastal Advisors LLC Insurance Consultants

                                Rich has been providing insurance solutions for his clients in the

                               NFL and NHL for over 30 years.</p>
                        </div>
                    </div>
                </ng-template>

                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/img/team/Meril.jpeg" alt="Image">
                            <ul class="team-link">
                                <!-- <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li> -->
                            </ul>
                        </div>
                        <div class="team-content">
                            <h3>Merril Hoge & Find A way</h3>
                            <span>Relationship Development</span>
                            <p>Merril Hoge, a former professional football player, played in the National Football League for The  Pittsburgh Steelers and Chicago Bears.</p>
                        </div>
                    </div>
                </ng-template>

                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/img/team/Ron.jpeg" alt="Image">
                            <ul class="team-link">
                                <!-- <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li> -->
                            </ul>
                        </div>
                        <div class="team-content">
                            <h3>Erik Coleman</h3>
                            <span>Erik is a former National Football League player for the New York Jets, Atlanta Falcons and Detroit Lions.</span>
                            <p>Erik is a former National Football League player for the New York Jets, Atlanta Falcons and Detroit Lions.



                                Erik Is a Vice President at NFP, a leading insurance broker and consultant that works with companies of all sizes, as well as individuals for their property and casualty needs.</p>
                        </div>
                    </div>
                </ng-template>

                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/img/team/colin.jpg" alt="Image">
                            <ul class="team-link">
                                <!-- <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li> -->
                            </ul>
                        </div>
                        <div class="team-content">
                            <h3>Colin Young</h3>
                            <span>Colin Young is a former Professional Baseball player for the Colorado Rockies and Boston Red Sox organizations.</span>
                            <p>He has also coached in the MLB for the San Diego Padres.  Along with being a life insurance consultant, Colin is a Personal Risk Specialist focusing on Home, Auto, and Liability insurance for families and individuals.</p>
                        </div>
                    </div>
                </ng-template>

                <!-- <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/img/team/team-5.jpg" alt="Image">
                            <ul class="team-link">
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                            </ul>
                        </div>
                        <div class="team-content">
                            <h3>Ronald Phaneuf</h3>
                            <span>Marketing Support</span>
                        </div>
                    </div>
                </ng-template>

                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/img/team/team-6.jpg" alt="Image">
                            <ul class="team-link">
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                            </ul>
                        </div>
                        <div class="team-content">
                            <h3>Maria Sledge</h3>
                            <span>Agent</span>
                        </div>
                    </div>
                </ng-template> -->
            </owl-carousel-o>
        </div>
    </div>
</section>
<!-- End Team Area -->
