<!-- Start Header Area -->
<header class="header-area">

    <!-- Start Top Header -->
    <div class="top-header">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-7">
                    <ul class="header-left-content">
                        <li>
                            <i class="bx bx-envelope"></i>
                            <a href="mailto:support@livingbenefitsteam.com">chris.muro@livingbenefits-team.com</a>
                        </li>
                        <!-- <li>
                            <i class="bx bx-location-plus"></i>
                            2364 Oakdale Avenue Clearwater, FL 34620, New York, USA
                        </li> -->
                    </ul>
                </div>

                <div class="col-lg-4 col-md-5">
                    <div class="header-right-content">
                        <ul class="language-area">
                            <li class="language-item-top">
                                <a href="#" class="language-bar">
                                    <!-- <span>Language</span> -->
                                    <!-- <i class="bx bx-chevron-down"></i> -->
                                </a>

                                <ul class="language-item-bottom">
                                    <li class="language-item">
                                        <a href="#" class="language-link">
                                            <img src="assets/img/language/english.png" alt="Image">
                                            English
                                        </a>
                                    </li>
                                    <li class="language-item">
                                        <a href="#" class="language-link">
                                            <img src="assets/img/language/arab.png" alt="Image">
                                            العربيّة
                                        </a>
                                    </li>
                                    <li class="language-item">
                                        <a href="#" class="language-link">
                                            <img src="assets/img/language/germany.png" alt="Image">
                                            Deutsch
                                        </a>
                                    </li>
                                    <li class="language-item">
                                        <a href="#" class="language-link">
                                            <img src="assets/img/language/portugal.png" alt="Image">
                                            󠁥󠁮󠁧󠁿Português
                                        </a>
                                    </li>
                                    <li class="language-item">
                                        <a href="#" class="language-link">
                                            <img src="assets/img/language/china.png" alt="Image">
                                            简体中文
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <div class="log-in">
                            <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                Log In
                            </a> -->
                        </div>

                        <div class="register">
                            <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop-2">
                                Register
                            </a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Start Top Header -->

    <!-- Start Navbar Area -->
    <div class="navbar-area">
        <div class="mobile-nav">
            <div class="container-fluid">
                <div class="mobile-menu">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/img/logo3.png" alt="logo"></a>
                    </div>
                </div>
            </div>
        </div>

        <div class="desktop-nav">
            <div class="container-fluid">
                <nav class="navbar navbar-expand-md navbar-light">
                    <a class="navbar-brand" routerLink="/"><img src="assets/img/logo4.png" alt="logo"></a>

                    <div class="collapse navbar-collapse mean-menu">
                        <ul class="navbar-nav m-auto">
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link">Home <i class="bx bx-chevron-down"></i></a>

                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Back Home</a></li>

                                    <!-- <li class="nav-item"><a routerLink="/index-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Demo - Two</a></li>

                                    <li class="nav-item"><a routerLink="/index-3" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Demo - Three</a></li> -->
                                </ul>
                            </li>

                            <!-- <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link">Pages <i class="bx bx-chevron-down"></i></a>

                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/about-us" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link">About Us</a></li>

                                    <li class="nav-item"><a routerLink="/testimonials" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link">Testimonials</a></li>

                                    <li class="nav-item"><a routerLink="/services" class="nav-link">Services</a></li>

                                    <li class="nav-item"><a routerLink="/team" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link">Team</a></li>

                                    <li class="nav-item">
                                        <a href="javascript:void(0)" class="nav-link">User <i class="bx bx-chevron-right"></i></a>

                                        <ul class="dropdown-menu">
                                            <li class="nav-item"><a routerLink="/my-account" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link">My Account</a></li>

                                            <li class="nav-item"><a routerLink="/recover-password" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link">Recover Password</a></li>
                                        </ul>
                                    </li>

                                    <li class="nav-item"><a routerLink="/pricing" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link">Pricing</a></li>

                                    <li class="nav-item"><a routerLink="/claims" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link">Claims</a></li>

                                    <li class="nav-item"><a routerLink="/faq" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link">FAQ</a></li>

                                    <li class="nav-item"><a routerLink="/privacy-policy" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link">Privacy Policy</a></li>

                                    <li class="nav-item"><a routerLink="/terms-conditions" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link">Terms & Conditions</a></li>

                                    <li class="nav-item"><a routerLink="/coming-soon" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link">Coming Soon</a></li>

                                    <li class="nav-item"><a routerLink="/404" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link">404 Error Page</a></li>
                                </ul>
                            </li>

                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link">Insurance <i class="bx bx-chevron-down"></i></a>

                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/business-insurance" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link">Business Insurance</a></li>

                                    <li class="nav-item"><a routerLink="/health-insurance" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link">Health Insurance</a></li>

                                    <li class="nav-item"><a routerLink="/life-insurance" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link">Life Insurance</a></li>

                                    <li class="nav-item"><a routerLink="/car-insurance" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link">Car Insurance</a></li>
                                </ul>
                            </li>

                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link">Blog <i class="bx bx-chevron-down"></i></a>

                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/blog-grid" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link">Blog Grid</a></li>

                                    <li class="nav-item"><a routerLink="/blog-right-sidebar" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link">Blog Right Sidebar</a></li>

                                    <li class="nav-item"><a routerLink="/blog-details" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link">Blog Details</a></li>
                                </ul>
                            </li> -->

                            <!-- <li class="nav-item"><a routerLink="/contact-us" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link">Contact Us</a></li> -->
                        </ul>

                        <div class="others-option">
                            <!-- <form class="search-box">
                                <input type="text" name="Search" placeholder="Search for..." class="form-control">
                                <button type="submit" class="search-btn"><i class="bx bx-search"></i></button>
                                <button type="submit" class="close-btn"><i class="bx bx-x"></i></button>
                            </form> -->

                            <div class="call-us">
                                <i class="bx bx-phone-call"></i>
                                <a href="tel:+1-(514)-312-5678">+1 5617151510</a>
                            </div>

                            <div class="get-quote">
                                <a routerLink="/contact-us" class="default-btn">Contact Us</a>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>

        <div class="others-option-for-responsive">
            <div class="container">
                <div class="dot-menu">
                    <div class="inner">
                        <div class="circle circle-one"></div>
                        <div class="circle circle-two"></div>
                        <div class="circle circle-three"></div>
                    </div>
                </div>

                <div class="container">
                    <div class="option-inner">
                        <div class="others-option justify-content-center d-flex align-items-center">
                            <div class="call-us">
                                <i class="bx bx-phone-call"></i>
                                <a href="tel:+1-(514)-312-5678">+1 5617151510</a>
                            </div>

                            <!-- <div class="get-quote">
                                <a routerLink="/pricing" class="default-btn">Get a Quote</a>
                            </div> -->

                            <!-- <form class="search-box">
                                <input type="text" name="Search" placeholder="Search for..." class="form-control">
                                <button type="submit" class="search-btn"><i class="bx bx-search"></i></button>
                                <button type="submit" class="close-btn"><i class="bx bx-x"></i></button>
                            </form> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Navbar Area -->

</header>
<!-- End Header Area -->
