<!-- Start Page Title Area -->
<div class="page-title-area bg-1">
    <div class="container">
        <div class="page-title-content">
            <h2>About Us</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">About Us</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Welcome Area -->
<section class="welcome-area pt-100">
    <div class="container">
        <div class="section-title">
            <span>Living Benefits Team</span>
            <h2>Since 25 Years, Our Company Has Been Working Very Faithfully</h2>
        </div>

        <div class="welcome-bg ptb-100">
            <div class="welcome-content">
                <h2>Our Goal Is to Provide Quality Service to Customers</h2>
                <p class="some-bold">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
            </div>
        </div>
    </div>
</section>
<!-- End Welcome Area -->

<app-funfacts></app-funfacts>

<app-homeone-why-choose-us></app-homeone-why-choose-us>

<app-homeone-feedback></app-homeone-feedback>

<app-team></app-team>

<app-partner></app-partner>
