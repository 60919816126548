<!-- Start Services Area -->
<section class="services-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Featured Services</span>
            <h2>All The Services That Our Team Provides</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <i class="flaticon-life-insurance-1"></i>
                    <h3>Life Insurance</h3>
                    <p style="color:rgb(11, 8, 141)">Term, Indexed Universal, Whole Life, and Premium Financing</p>
                    <!-- <a routerLink="/business-insurance" class="read-more">Read More</a> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <i class="flaticon-home-insurance"></i>
                    <h3>Property and Casualty Insurance</h3>
                    <p style="color:rgb(11, 8, 141)">Home, Auto, Business, Liability</p>
                    <!-- <a routerLink="/business-insurance" class="read-more">Read More</a> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <i class="flaticon-insurance"></i>
                    <h3>Disability Income & Long Term Care</h3>
                    <p style="color:rgb(11, 8, 141)">Disability Income and long-term care insurance can provide important financial protection in case of a disability or long-term care need.</p>
                    <!-- <a routerLink="/business-insurance" class="read-more">Read More</a> -->
                </div>
            </div>

            <!-- <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <i class="flaticon-property-insurance"></i>
                    <h3>Property Insurance</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <a routerLink="/business-insurance" class="read-more">Read More</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <i class="flaticon-travel-insurance"></i>
                    <h3>Travel Insurance</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <a routerLink="/business-insurance" class="read-more">Read More</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <i class="flaticon-car-insurance"></i>
                    <h3>Auto Insurance</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <a routerLink="/business-insurance" class="read-more">Read More</a>
                </div>
            </div> -->

            <div class="col-lg-12 col-md-12">
                <!-- <a routerLink="/services" class="default-btn">View More</a> -->
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->
