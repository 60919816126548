<!-- Start Future Area -->
<section class="future-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Don't Get Stuck with Outdated Term Insurance</span>
            <h2 >Things To Consider When Comparing <br>
                Old Term Vs. New Term</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-future-box">
                    <i class="flaticon-life-insurance-1"></i>
                    <!-- <h3>Trustworthy Company</h3> -->
                    <p style="color:rgb(11, 8, 141)">How will I cover medical and living expenses if I suffer a Heart Attack or get Diagnosed with Cancer?</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-future-box">
                    <i class="flaticon-life-insurance-1"></i>
                    <!-- <h3>Income Protection</h3> -->
                    <p style="color:rgb(11, 8, 141)">New policies with Living benefits can provide a one-time payment of up to $1,000,000 to policyholders while they are still alive.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-future-box">
                    <i class="flaticon-life-insurance-1"></i>
                    <!-- <h3>Income Protection</h3> -->
                    <p style="color:rgb(11, 8, 141)">A stroke occurs every 40 seconds in the U.S.</p>
                </div>
            </div>


            <div class="col-lg-4 col-md-6">
                <div class="single-future-box">
                    <i class="flaticon-life-insurance-1"></i>
                    <!-- <h3>Income Protection</h3> -->
                    <p style="color:rgb(11, 8, 141)">It is estimated that 1.9 million new cases of Cancer will be diagnosed in 2023.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-future-box">
                    <i class="flaticon-life-insurance-1"></i>
                    <!-- <h3>Income Protection</h3> -->
                    <p style="color:rgb(11, 8, 141)">6.5 Million or more people are estimated to be living with Alzheimer’s or Dementia.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-future-box">
                    <i class="flaticon-life-insurance-1"></i>
                    <!-- <h3>Anytime Cancellation</h3> -->
                    <p style="color:rgb(11, 8, 141)">Every year, about 805,000 people in the United States have a heart attack.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Future Area -->
