<!-- Start Company Area -->
<section class="company-area bg-color">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="company-img">
                    <!-- <div class="trusted">
                        <span>100%</span>
                        <h3>Trusted Company</h3>
                        <p>You can depend on us for our company's services</p>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-content ptb-100">
                    <!-- <span class="top-title"><strong>Living Benefits Team</strong></span> -->
                    <h2>What Is Life Insurance With Living Benefits?</h2>
                    <p style="color:rgb(11, 8, 141)" class="some-bold">It's Life Insurance that you don't have to die to use. Living benefits come in the form of riders built into your policy at no additional cost. It allows the insured to access up to $1,000,000 from the policy's death benefit while still alive.</p>
                    <p></p>
                    <!-- <a routerLink="/about-us" class="default-btn">Read More</a> -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Company Area -->
