<!-- Start FAQ Area -->
<section class="faq-area bg-color">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12 p-0">
                <div >
                    <img src="assets/img/newgr.jpg" alt="Image">

                    <!-- <div class="faq-video">
                        <a href="https://www.youtube.com/watch?v=6TlMflQEpt8" class="popup-youtube">
                            <div class="video-button">
                                <i class="bx bx-play"></i>
                            </div>
                            <span>Watch Our Company’s Video</span>
                        </a>
                    </div> -->
                </div>
            </div>
            <div class="col-lg-6 col-md-12 p-0">
                <div >
                    <img src="assets/img/new1.jpg" alt="Image">

                    <!-- <div class="faq-video">
                        <a href="https://www.youtube.com/watch?v=6TlMflQEpt8" class="popup-youtube">
                            <div class="video-button">
                                <i class="bx bx-play"></i>
                            </div>
                            <span>Watch Our Company’s Video</span>
                        </a>
                    </div> -->
                </div>
            </div>



        </div>
    </div>
</section>
<!-- End FAQ Area -->
