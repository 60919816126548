<!-- Start Why Choose Us Area -->
<section class="why-choose-us-area bg-color">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="choose-us-content ptb-100">
                    <!-- <span class="top-title">Why Choose Us</span> -->
                    <h2>Whats Covered</h2>
                    <!-- <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint cupiditate, dolorem odio quia mollitia deleniti at error ratione qui vero ex provident pariatur</p> -->
                    <ul style="align-items: left">
                        <li style="color: crimson"><i class="flaticon-tick"></i>Cancer</li>
                        <li style="color: crimson"><i class="flaticon-tick"></i> Heart Attack</li>
                        <li style="color: crimson"><i class="flaticon-tick"></i>Stroke</li>
                        <li style="color: crimson"><i class="flaticon-tick"></i>Alzheimer's</li>
                        <li style="color: crimson"><i class="flaticon-tick"></i>ALS (Lou Gherig's disease)</li>
                        <li style="color: crimson"><i class="flaticon-tick"></i>Aorta Graft Surgery</li>
                        <li style="color: crimson"><i class="flaticon-tick"></i>Aplastic Anemia]</li>
                        <li style="color: crimson"><i class="flaticon-tick"></i>Blindness</li>
                        <li style="color: crimson"><i class="flaticon-tick"></i>Breast Cancer</li>
                        <li style="color: crimson"><i class="flaticon-tick"></i>End-Stage Renal Failure</li>
                        <li style="color: crimson"><i class="flaticon-tick"></i>Heart Valve Replacement</li>
                        <li style="color: crimson"><i class="flaticon-tick"></i>Major Organ Transplant</li>
                        <li style="color: crimson"><i class="flaticon-tick"></i>Motor Neuron Disease</li>
                        <li style="color: crimson"><i class="flaticon-tick"></i>Sudden Cardiac Arrest</li>



                    </ul>
                    <!-- <a routerLink="/about-us" class="-btn">Learn More</a> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-12 p-0">
                <div >
                    <div >
                        <!-- <owl-carousel-o [options]="whyChooseUsSliderOptions">
                            <ng-template carouselSlide>
                                <div class="why-choose-us-img bg-3"></div>
                            </ng-template> -->
                            <img src="assets/img/sergei.jpg" alt="Image">
                            <p style="text-align: center; font-style: bold;">"Chris Is More Than Just My Trusted Insurance Agent. He And I Have Become Good Friends."</p>
                            <p style="text-align: center; font-style: bold;">-Sergei Bobrovsky</p>

                            <!-- <ng-template carouselSlide>
                                <div class="why-choose-us-img bg-2"></div>
                            </ng-template>

                            <ng-template carouselSlide>
                                <div class="why-choose-us-img bg-3"></div>
                            </ng-template> -->
                        <!-- </owl-carousel-o> -->
                    </div>
                    <div class="success">
                        <span></span>
                        <h3> </h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Why Choose Us Area -->
