<!-- Start Testimonials Area -->
<section class="testimonials-area bg-color ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Our Feedback</span>
            <h2>What Our Clients Say</h2>
        </div>

        <div class="testimonials">
            <div class="testimonials-slider">
                <owl-carousel-o [options]="testimonialsSliderOptions">
                    <ng-template carouselSlide>
                        <div class="testimonials-item">
                            <img src="assets/img/testimonials/tre.png" alt="Image">
                            <p>“Chris knows the most important benefits to players after they step off the world stage. I would recommend his Living Benefits Program to all of my MVP players” <i class="flaticon-right-quote"></i></p>
                            <h3>Tom Reich</h3>
                            <span>Legendary Sports Agent</span>
                        </div>
                    </ng-template>

                    <ng-template carouselSlide>
                        <div class="testimonials-item">
                            <img src="assets/img/testimonials/yh.png" alt="Image">
                            <p>“Chris Is More Than Just My Trusted Insurance Agent. He And I Have Become Good Friends.” <i class="flaticon-right-quote"></i></p>
                            <h3>Sergei Bobrovsky</h3>
                            <span>#72 Florida Panthers Sergei Bobrovsky</span>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="testimonials-item">
                            <img src="assets/img/testimonials/round.png" alt="Image">
                            <p>“The living Benefits are so important. It was like a miracle for my family. It didn’t erase my heart attack, but it took a lot of stress off, which is keeping me healthier.” <i class="flaticon-right-quote"></i></p>
                            <h3>Joseph Cartigiano</h3>
                            <span>Valued Client</span>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="testimonials-item">
                            <img src="assets/img/testimonials/matt.png" alt="Image">
                            <p>“Unfortunately for us, we needed the living benefits rider due to a medical issue. Chris’s expertise was spot on!” <i class="flaticon-right-quote"></i></p>
                            <h3>Matthew Bloom</h3>
                            <span>Valued Client</span>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
<!-- Eed Testimonials Area -->
